import React, { useState, useEffect, useContext } from 'react'
import { NumericFormat } from 'react-number-format';
import { useParams, Link, useNavigate } from 'react-router-dom'
import 'lightbox.js-react/dist/index.css'
import {initLightboxJS} from 'lightbox.js-react'
import Axios from 'axios'
import Moment from 'moment';
import './cardV2.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CoverDisplay from '../components/CoverDisplay';
import ImageDisplay from '../components/ImageDisplay';
import { AuthContext } from '../../context/AuthProvider'
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BannerImage from '../assets/Space-4.jpg'

function Card() {
  const { user } = useContext(AuthContext);
  const {id} = useParams()
  const [cardID, setCardID] = useState(null)
  const [PSAdataTotalPopulation, setPSAdataTotalPopulation] = useState(null)
  const [PSAdataGradeDesc, setPSAdataGradeDesc] = useState(null)
  const [PSAdataCardGrade, setPSAdataCardGrade] = useState(null)
  const [submission, setSubmission] = useState(null)
  const [key, setKey] = useState('info');
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

// First useEffect to handle fetching data based on id
useEffect(() => {
  Axios.get(`https://api.alientrader.cards/card/${id}`)
      .then((response) => {
          setCardID(response.data);
          return Axios.get(`https://api.alientrader.cards/card/subtext/${response.data[0].card_grade_sub}`);
      })
      .then((response) => {
          setSubmission(response.data);
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
}, [id]);

// Second useEffect to handle the third API call once cardID is set
useEffect(() => {
  // Check if card_grader is "PSA" and card_grade_cert has a value before making the third API call
  if (cardID && cardID[0].card_grader === 'PSA' && cardID[0].card_grade_cert) {
      const settings = {
          method: 'GET',
          url: `https://api.psacard.com/publicapi/cert/GetByCertNumber/${cardID[0].card_grade_cert}`,
          headers: {
              'authorization': 'bearer fwAy4rWaDjtE7bG6uCfuIFx9E75Q7xiZ2fqH_B5Mrg_djOb1z4QIYP-99XL3tI5zZj5s6e6eGPpoExFlYzHpZBlLfatEM1hkKTLoHIzhyZfv_ByJ9D5uaSPOSKPMrI66zdMdw1KnvvWvRvJRKRyDr_80g0CwOqmjVIAEc4BdxOXlftcxpWVZyMqt5n2dCMyxebyb7vnx3i7FyguyBLOUxfGjP2XIr0o_WphYAxNpaYqgkLki8hvJadXHlAzByGWo8T0p8fC0cW15LuD9VbFywaKUhIEz4aq2WjqNfwsbHot2gzHh'
          }
      };
      Axios(settings)
          .then((response) => {
              console.log(response.data);  // Process the third API call's response here
              setPSAdataTotalPopulation(response.data.PSACert.TotalPopulation)
              setPSAdataGradeDesc(response.data.PSACert.GradeDescription)
              setPSAdataCardGrade(response.data.PSACert.CardGrade)
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
  }
}, [cardID]);
useEffect(() => {
  initLightboxJS("5BD5-3ED0-49E2-95DC", "Individual");
});

  if (!cardID || !submission) {
    return <div>Card {id} Loading...</div>;
  }
  const subText = submission.sub_text
  const cardData = cardID[0];
  const cardPrice = cardData.card_price ? cardData.card_price.toFixed(2) : "-";
  const cardFeeGrade = cardData.card_fee_grade ? cardData.card_fee_grade.toFixed(2) : "-";
  const cardFeeShipTax = cardData.card_fee_shiptax ? cardData.card_fee_shiptax.toFixed(2) : "-";
  const cardTotalCost = cardData.card_total_cost ? cardData.card_total_cost.toFixed(2) : "-";
  const cardSoldPrice = cardData.card_sold_price ? cardData.card_sold_price.toFixed(2) : "-";
  const cardSoldFees = cardData.card_sold_fees ? cardData.card_sold_fees.toFixed(2) : "-";
  const cardSoldFeesAds = cardData.card_sold_fees_ads ? cardData.card_sold_fees_ads.toFixed(2) : "-";
  const cardSoldShip = cardData.card_solds_ship ? cardData.card_solds_ship.toFixed(2) : "-";
  const cardProfit = cardData.card_profit ? cardData.card_profit.toFixed(2) : "-";
  const cardSoldDate = cardData.card_sold_date ? Moment(cardData.card_sold_date).format('MM-DD-YYYY') : "-";
  const cardRecDate = cardData.card_date_received ? Moment(cardData.card_date_received).format('MM-DD-YYYY') : "-";
  const cardUpdatedDate = cardData.updated_date ? Moment(cardData.updated_date).format('MM-DD-YYYY HH:mm') : "-";
  const cardProfitCalc = (cardData.card_total_cost * 1.2) + 5
  const cardProfitAt = cardData.card_total_cost ? cardProfitCalc.toFixed(2) : "-"
  const cardCentering = cardData.card_grade_centering ? (cardData.card_grade_centering * 100).toFixed(2) : "-";

//Card Images
let modifiedGraderFrontData;
if (cardData.grader_front_url && cardData.grader_front_url !== "undefined") {
  const imageGraderFrontData = JSON.parse(cardData.grader_front_url);
  modifiedGraderFrontData = imageGraderFrontData.map((image) => {
    const modifiedGraderFrontData = { ...image };
    // Modify .name property
    if (modifiedGraderFrontData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedGraderFrontData.name = modifiedGraderFrontData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedGraderFrontData.name = "https://api.alientrader.cards/" + modifiedGraderFrontData.name;
    }
    // Modify .thumbnail property if it exists
    if (modifiedGraderFrontData.thumbnail) {
      if (modifiedGraderFrontData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedGraderFrontData.thumbnail = modifiedGraderFrontData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedGraderFrontData.thumbnail = "https://api.alientrader.cards/" + modifiedGraderFrontData.thumbnail;
      }
    }
    return modifiedGraderFrontData;
  });
}
let modifiedGraderBackData;
if (cardData.grader_back_url && cardData.grader_back_url !== "undefined") {
  const imageGraderBackData = JSON.parse(cardData.grader_back_url);
  modifiedGraderBackData = imageGraderBackData.map((image) => {
    const modifiedGraderBackData = { ...image };
    // Modify .name property
    if (modifiedGraderBackData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedGraderBackData.name = modifiedGraderBackData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedGraderBackData.name = "https://api.alientrader.cards/" + modifiedGraderBackData.name;
    }
    // Modify .thumbnail property if it exists
    if (modifiedGraderBackData.thumbnail) {
      if (modifiedGraderBackData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedGraderBackData.thumbnail = modifiedGraderBackData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedGraderBackData.thumbnail = "https://api.alientrader.cards/" + modifiedGraderBackData.thumbnail;
      }
    }
    return modifiedGraderBackData;
  });
}
let modifiedScanFrontData;
if (cardData.scan_front_url && cardData.scan_front_url !== "undefined") {
  const imageScanFrontData = JSON.parse(cardData.scan_front_url);
  modifiedScanFrontData = imageScanFrontData.map((image) => {
    const modifiedScanFrontData = { ...image };
    // Modify .name property
    if (modifiedScanFrontData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedScanFrontData.name = modifiedScanFrontData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedScanFrontData.name = "https://api.alientrader.cards/" + modifiedScanFrontData.name;
    }
    // Modify .thumbnail property if it exists
    if (modifiedScanFrontData.thumbnail) {
      if (modifiedScanFrontData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedScanFrontData.thumbnail = modifiedScanFrontData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedScanFrontData.thumbnail = "https://api.alientrader.cards/" + modifiedScanFrontData.thumbnail;
      }
    }
    return modifiedScanFrontData;
  });
}
let modifiedScanBackData;
if (cardData.scan_back_url && cardData.scan_back_url !== "undefined") {
  const imageScanBackData = JSON.parse(cardData.scan_back_url);
  modifiedScanBackData = imageScanBackData.map((image) => {
    const modifiedScanBackData = { ...image };
    if (modifiedScanBackData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedScanBackData.name = modifiedScanBackData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedScanBackData.name = "https://api.alientrader.cards/" + modifiedScanBackData.name;
    }
    if (modifiedScanBackData.thumbnail) {
      if (modifiedScanBackData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedScanBackData.thumbnail = modifiedScanBackData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedScanBackData.thumbnail = "https://api.alientrader.cards/" + modifiedScanBackData.thumbnail;
      }
    }
    return modifiedScanBackData;
  });
}
let modifiedAlienTraderFrontData;
if (cardData.alientrader_front_url && cardData.alientrader_front_url !== "undefined") {
  const imageAlienTraderFrontData = JSON.parse(cardData.alientrader_front_url);
  modifiedAlienTraderFrontData = imageAlienTraderFrontData.map((image) => {
    const modifiedAlienTraderFrontData = { ...image };
    if (modifiedAlienTraderFrontData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedAlienTraderFrontData.name = modifiedAlienTraderFrontData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedAlienTraderFrontData.name = "https://api.alientrader.cards/" + modifiedAlienTraderFrontData.name;
    }
    if (modifiedAlienTraderFrontData.thumbnail) {
      if (modifiedAlienTraderFrontData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedAlienTraderFrontData.thumbnail = modifiedAlienTraderFrontData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedAlienTraderFrontData.thumbnail = "https://api.alientrader.cards/" + modifiedAlienTraderFrontData.thumbnail;
      }
    }
    return modifiedAlienTraderFrontData;
  });
}
let modifiedAlienTraderBackData;
if (cardData.alientrader_back_url && cardData.alientrader_back_url !== "undefined") {
  const imageAlienTraderBackData = JSON.parse(cardData.alientrader_back_url);
  modifiedAlienTraderBackData = imageAlienTraderBackData.map((image) => {
    const modifiedAlienTraderBackData = { ...image };
    if (modifiedAlienTraderBackData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedAlienTraderBackData.name = modifiedAlienTraderBackData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedAlienTraderBackData.name = "https://api.alientrader.cards/" + modifiedAlienTraderBackData.name;
    }
    if (modifiedAlienTraderBackData.thumbnail) {
      if (modifiedAlienTraderBackData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedAlienTraderBackData.thumbnail = modifiedAlienTraderBackData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedAlienTraderBackData.thumbnail = "https://api.alientrader.cards/" + modifiedAlienTraderBackData.thumbnail;
      }
    }
    return modifiedAlienTraderBackData;
  });
}
let modifiedMiscImage1Data;
if (cardData.misc_image_url && cardData.misc_image_url !== "undefined") {
  const imageMiscImage1Data = JSON.parse(cardData.misc_image_url);
  modifiedMiscImage1Data = imageMiscImage1Data.map((image) => {
    const modifiedMiscImage1Data = { ...image };
    if (modifiedMiscImage1Data.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedMiscImage1Data.name = modifiedMiscImage1Data.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedMiscImage1Data.name = "https://api.alientrader.cards/" + modifiedMiscImage1Data.name;
    }
    if (modifiedMiscImage1Data.thumbnail) {
      if (modifiedMiscImage1Data.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedMiscImage1Data.thumbnail = modifiedMiscImage1Data.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedMiscImage1Data.thumbnail = "https://api.alientrader.cards/" + modifiedMiscImage1Data.thumbnail;
      }
    }
    return modifiedMiscImage1Data;
  });
}
let modifiedMiscImage2Data;
if (cardData.misc_image2_url && cardData.misc_image2_url !== "undefined") {
  const imageMiscImage2Data = JSON.parse(cardData.misc_image2_url);
  modifiedMiscImage2Data = imageMiscImage2Data.map((image) => {
    const modifiedMiscImage2Data = { ...image };
    if (modifiedMiscImage2Data.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedMiscImage2Data.name = modifiedMiscImage2Data.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedMiscImage2Data.name = "https://api.alientrader.cards/" + modifiedMiscImage2Data.name;
    }
    if (modifiedMiscImage2Data.thumbnail) {
      if (modifiedMiscImage2Data.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedMiscImage2Data.thumbnail = modifiedMiscImage2Data.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedMiscImage2Data.thumbnail = "https://api.alientrader.cards/" + modifiedMiscImage2Data.thumbnail;
      }
    }
    return modifiedMiscImage2Data;
  });
}
let modifiedMiscImage3Data;
if (cardData.misc_image3_url && cardData.misc_image3_url !== "undefined") {
  const imageMiscImage3Data = JSON.parse(cardData.misc_image3_url);
  modifiedMiscImage3Data = imageMiscImage3Data.map((image) => {
    const modifiedMiscImage3Data = { ...image };
    if (modifiedMiscImage3Data.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedMiscImage3Data.name = modifiedMiscImage3Data.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "https://alientrader.net/"
      );
    } else {
      modifiedMiscImage3Data.name = "https://api.alientrader.cards/" + modifiedMiscImage3Data.name;
    }
    if (modifiedMiscImage3Data.thumbnail) {
      if (modifiedMiscImage3Data.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedMiscImage3Data.thumbnail = modifiedMiscImage3Data.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedMiscImage3Data.thumbnail = "https://api.alientrader.cards/" + modifiedMiscImage3Data.thumbnail;
      }
    }
    return modifiedMiscImage3Data;
  });
}
//Cover Image 
  let modifiedCoverImageData
  if (cardData.cover_image === "none") {
    modifiedCoverImageData = [
      {
        name: "https://alientrader.net/cards/cards/images/cards/Temp.jpg",
        usrName: "Temp.jpg",
        size: 15850712,
        type: "image/jpeg",
        thumbnail: "https://alientrader.net/cards/cards/images/cards/Temp.jpg",
        thumbnail_type: "image/jpeg",
        thumbnail_size: 55646,
        searchStr: "Temp.jpg,!:sStrEnd"
      },
    ]
  } else {
    const imageCoverData = JSON.parse(cardData.cover_image)
    modifiedCoverImageData = imageCoverData.map((image) => {
      const modifiedCoverImage = { ...image };
      if (modifiedCoverImage.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedCoverImage.name = modifiedCoverImage.name.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedCoverImage.name = "https://api.alientrader.cards/" + modifiedCoverImage.name;
      }
      if (modifiedCoverImage.thumbnail) {
        if (modifiedCoverImage.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
          modifiedCoverImage.thumbnail = modifiedCoverImage.thumbnail.replace(
            "/home/u825454141/domains/alientrader.net/public_html/",
            "https://alientrader.net/"
          );
        } else {
          modifiedCoverImage.thumbnail = "https://api.alientrader.cards/" + modifiedCoverImage.thumbnail;
        }
      }
      return modifiedCoverImage;
    });
  }
  let modifiedCoverImageBackData;
  if (cardData.cover_image_back === "none") {
    modifiedCoverImageBackData = [
      {
        name: "https://alientrader.net/cards/cards/images/cards/Temp.jpg",
        usrName: "TempBack.jpg",
        size: 15850712,
        type: "image/jpeg",
        thumbnail: "https://alientrader.net/cards/cards/images/cards/Temp.jpg",
        thumbnail_type: "image/jpeg",
        thumbnail_size: 55646,
        searchStr: "TempBack.jpg,!:sStrEnd"
      },
    ];
  } else {
    const imageCoverBackData = JSON.parse(cardData.cover_image_back);
    modifiedCoverImageBackData = imageCoverBackData.map((image) => {
      const modifiedCoverBackImage = { ...image };
      if (modifiedCoverBackImage.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedCoverBackImage.name = modifiedCoverBackImage.name.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "https://alientrader.net/"
        );
      } else {
        modifiedCoverBackImage.name = "https://api.alientrader.cards/" + modifiedCoverBackImage.name;
      }
      if (modifiedCoverBackImage.thumbnail) {
        if (modifiedCoverBackImage.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
          modifiedCoverBackImage.thumbnail = modifiedCoverBackImage.thumbnail.replace(
            "/home/u825454141/domains/alientrader.net/public_html/",
            "https://alientrader.net/"
          );
        } else {
          modifiedCoverBackImage.thumbnail = "https://api.alientrader.cards/" + modifiedCoverBackImage.thumbnail;
        }
      }
      return modifiedCoverBackImage;
    });
  }

  return (
    <div className="blackHome">
      <div className='cardV2TopBG' style={{ backgroundImage: `url(${BannerImage})` }}>
        <div className='cardID'>Card #{cardData.card_id}</div>
        <div className='actions'>
          <div className='goBack' onClick={goBack}><ArrowBackIcon fontSize="large"/></div>
          <div className='cardViewEdit'>{user && user.role === 'admin' && <Link to={`/edit/card/${cardData.card_id}`}><EditIcon  fontSize="large"/></Link>}</div>
        </div>
      </div>
      <div className='cardV2ImageBox'>
        <CoverDisplay
          frontImage={modifiedCoverImageData[0]} // Assuming there's only one front image
          backImage={modifiedCoverImageBackData[0]} // Assuming there's only one back image
        />
      </div>
      <div className='card'>
      <div className='card-set'>{cardData.card_set_year} {cardData.card_game} {cardData.card_set}</div>
      <div className='card-title'>{cardData.card_pokemon} {cardData.card}</div>
      <Tabs id="card-information" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
      <Tab eventKey="info" title="Main Info">
      <Container>
        <Row className='d-flex justify-content-center'>
          <Col xs={10} sm={6} md={5} lg={5} xl={5} className='custom-info-box shadow-sm'>
            <Row><div className='custom-info-header'>Card Info</div></Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Game:</div></Col>
              <Col>
                <div className='custom-info-val'><a href={`/dashboard/card-wall/game/${cardData.card_game}`}>{cardData.card_game}</a></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Pokemon / Character / Player:</div></Col>
              <Col>
                <div className='custom-info-val'><a href={`/dashboard/card-wall/${cardData.card_pokemon}`}>{cardData.card_pokemon}</a></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Variety / Pedigree:</div></Col>
              <Col>
                <div className='custom-info-val'>{cardData.card}</div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Language:</div></Col>
              <Col>
                <div className='custom-info-val'><a href={`/dashboard/card-wall/language/${cardData.card_language}`}>{cardData.card_language}</a></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Year:</div></Col>
              <Col>
                <div className='custom-info-val'><a href={`/dashboard/card-wall/set-year/${cardData.card_set_year}`}>{cardData.card_set_year}</a></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Set:</div></Col>
              <Col>
                <div className='custom-info-val'><a href={`/dashboard/card-wall/set/${cardData.card_set}`}>{cardData.card_set}</a></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Card Number:</div></Col>
              <Col>
                <div className='custom-info-val'>{cardData.card_number}</div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Owner:</div></Col>
              <Col>
                <div className='custom-info-val'><a href={`/dashboard/card-wall/owner/${cardData.card_owner}`}>{cardData.card_owner}</a></div>
              </Col>
            </Row>
          </Col>
          <Col xs={10} sm={5} md={5} lg={5} xl={5} className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Grading Info</div></Row>
          <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Grader:</div></Col>
              <Col><div className='custom-info-val'><a href={`/dashboard/card-wall/graded/${cardData.card_grader}`}>{cardData.card_grader}</a></div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Grade Number:</div></Col>
              <Col><div className='custom-info-val'>{cardData.card_grade_number}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Grade:</div></Col>
              <Col><div className='custom-info-val'>{cardData.card_grade}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Certification #:</div></Col>
              <Col><div className='custom-info-val'>{cardData.card_grade_cert}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Link:</div></Col>
              <Col><div className='custom-info-val'>{cardData.card_grade_link ? <Link to={cardData.card_grade_link} target="_blank">LINK</Link> : ""}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Submission:</div></Col>
              <Col><div className='custom-info-val'><a href={`/dashboard/submissions/${cardData.card_grade_sub}`}>{subText}</a></div></Col>
            </Row>
            {cardData.card_grade_centering && (<Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Centering:</div></Col>
              <Col><div className='custom-info-val'>{cardData.card_grade_centering ? cardCentering + "%" : ""}</div></Col>
            </Row>)}
            {cardData.card_pregrade && (<Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Pre-Grade:</div></Col>
              <Col><div className='custom-info-val'>{cardData.card_pregrade}</div></Col>
            </Row>)}
            {PSAdataTotalPopulation && (<Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>PSA Population:</div></Col>
              <Col><div className='custom-info-val'>{PSAdataTotalPopulation}</div></Col>
            </Row>)}
            {cardData.card_grade_cert_cracked_ID_new && (<Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>New Grade ID:</div></Col>
              <Col>
                <div className='custom-info-val'>
                  <a href={`/card/${cardData.card_grade_cert_cracked_ID_new}`} rel="noopener noreferrer">LINK</a>
                </div>
              </Col>
            </Row>)}
          </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
          {user && user.role === 'admin' && (
          <Col xs={10} sm={6} md={5} lg={5} xl={3} className='custom-info-box shadow-sm'>
            <Row><div className='custom-info-header'>Cost Info</div></Row>
            <Row className='d-flex justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Cost:</div></Col>
              <Col>
              <div className='custom-info-val'><NumericFormat value={cardPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Grading Fees:</div></Col>
              <Col>
              <div className='custom-info-val'><NumericFormat value={cardFeeGrade} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Ship & Tax:</div></Col>
              <Col>
              <div className='custom-info-val'><NumericFormat value={cardFeeShipTax} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Total Cost:</div></Col>
              <Col><div className='custom-info-val'><NumericFormat value={cardTotalCost} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Profitability:</div></Col>
              <Col><div className='custom-info-val'>${cardProfitAt}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Received Date:</div></Col>
              <Col><div className='custom-info-val'>{cardRecDate}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Purchased At:</div></Col>
              <Col>
                <div className='custom-info-val'>
                  {cardData.card_purchased_at === "Cracked" ? (
                    <a href={`/card/${cardData.card_grade_cert_cracked_ID}`} rel="noopener noreferrer">Cracked</a>
                  ) : (
                    cardData.card_purchased_at
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          )}
          {user && user.role === 'admin' && (
          <Col xs={10} sm={5} md={5} lg={5} xl={3} className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Sold Info</div></Row>
          <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Sold Price:</div></Col>
              <Col>
                <div className='custom-info-val'><NumericFormat value={cardSoldPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Sold Fees:</div></Col>
              <Col>
              <div className='custom-info-val'><NumericFormat value={cardSoldFees} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Sold Ad Fees:</div></Col>
              <Col>
              <div className='custom-info-val'><NumericFormat value={cardSoldFeesAds} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Shipping Fees:</div></Col>
              <Col><div className='custom-info-val'><NumericFormat value={cardSoldShip} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Sold Date:</div></Col>
              <Col><div className='custom-info-val'>{cardSoldDate}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Sold At:</div></Col>
              <Col><div className='custom-info-val'>{cardData.card_sold_at}</div></Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={5}><div className='custom-info-title'>Profit:</div></Col>
              <Col><div className='cardProfitValue'><NumericFormat value={cardProfit} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div></Col>
            </Row>
          </Col>
          )}
          {user && user.role === 'admin' && (
            <Col xs={10} sm={11} md={10} lg={10} xl={4} className='custom-note-box shadow-sm'>
              <Row><div className='custom-info-header'>Misc Info</div></Row>
              <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} sm={3}><div className='custom-info-title'>eBay Title:</div></Col>
                <Col>
                  <div className='custom-info-misc'>{cardData.card_set_year} {cardData.card_game} {cardData.card_pokemon} {cardData.card_set} {cardData.card_number} {cardData.card_language} {cardData.card} {cardData.card_grader} {cardData.card_grade_number}</div>
                </Col>
              </Row>
              {cardData.ebay_listing && (<Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} sm={3}><div className='custom-info-title'>eBay Listing:</div></Col>
                <Col>
                  <div className='custom-info-misc'>
                    {cardData.ebay_listing ? <Link to={cardData.ebay_listing}>LINK</Link> : ""}
                  </div>
                </Col>
              </Row>)}
              {cardData.card_link && (<Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} sm={3}><div className='custom-info-title'>Card Link:</div></Col>
                <Col>
                  <div className='custom-info-misc'>
                    {cardData.card_link ? <Link to={cardData.card_link}>LINK</Link> : ""}
                  </div>
                </Col>
              </Row>)}
              {cardData.card_grade_sub_order && (
                <Row className='d-flex align-items-center justify-content-center'>
                  <Col xs={4} sm={3}>
                    <div className='custom-info-title'>Sub Order #:</div>
                  </Col>
                  <Col>
                    <div className='custom-info-misc'>
                      {cardData.card_grade_sub_order}
                     </div>
                  </Col>
                </Row>
              )}
              {cardData && cardData.card_grader === "PSA" && (<Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} sm={3}><div className='custom-info-title'>PSA Grade:</div></Col>
                <Col>
                  <div className='custom-info-misc'>
                    {PSAdataCardGrade} - {PSAdataGradeDesc}
                  </div>
                </Col>
              </Row>)}
              <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} sm={3}><div className='custom-info-title'>Notes:</div>
                </Col>
                <Col>
                  <div className='custom-info-misc'>{cardData.card_notes}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
      </Tab>
      <Tab eventKey="images" title="Images">
        <Container>
          <Row className='justify-content-center'> 
          {cardData.grader_front_url && modifiedGraderFrontData.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Grader Front</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.grader_back_url && modifiedGraderBackData.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Grader Back</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.scan_front_url && modifiedScanFrontData.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Scan Front</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.scan_back_url && modifiedScanBackData.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Scan Back</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.alientrader_front_url && modifiedAlienTraderFrontData.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Alien Trader Front</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.alientrader_back_url && modifiedAlienTraderBackData.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Alien Trader Back</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.misc_image_url && modifiedMiscImage1Data.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Miscellaneous</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.misc_image2_url && modifiedMiscImage2Data.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Miscellaneous</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          {cardData.misc_image3_url && modifiedMiscImage3Data.map((image, index) => (
            <Col key={cardData.card_id} sm={4} className='custom-card-box shadow-sm'>
              <Row>
                <div className='custom-info-header'>Miscellaneous</div>
              </Row>
              <Row className='card-images'>
                <ImageDisplay key={index} image={image} />
              </Row>
            </Col>
          ), [])}
          </Row>
        </Container>
      </Tab>
      </Tabs>
      <br />
      </div>
      <div className='actions'>
          <div className='goBack' onClick={goBack}><ArrowBackIcon fontSize="large"/></div>
          <div className='cardViewEditBlack'>{user && user.role === 'admin' && <Link to={`/edit/card/${cardData.card_id}`}><EditIcon  fontSize="large"/></Link>}</div>
        </div>
      <div className='cardID'>Updated {cardUpdatedDate}</div><br/>
    </div>
  )
}

export default Card