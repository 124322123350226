import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CoverDisplay from '../components/ImagesPage';
import "./searchDB.css";

type Result = {
  card_id: string;
  card_owner: string;
  cover_image: string;
  card_grade_number: string;
};

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    card_game: [],
    card_set_year: [],
    card_set: [],
    card_language: [],
    card_grader: [],
    card_grade_number: [],
    card_owner: [],
  });
  const [results, setResults] = useState<Result[]>([]);
  const [loading, setLoading] = useState(false);

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  // Fetch options dynamically based on selected filters
  const fetchFilterOptions = async (
    field: string,
    sortType: 'alphabetical' | 'numerical' = 'alphabetical',
    filters: Record<string, string> = {}
  ) => {
    try {
      const response = await axios.get('https://api.alientrader.cards/data/filter-options', {
        params: { field, ...filters },
      });

      let sortedOptions = response.data;

      if (sortType === 'alphabetical') {
        sortedOptions = sortedOptions.sort((a: string, b: string) => a.localeCompare(b));
      } else if (sortType === 'numerical') {
        sortedOptions = sortedOptions.sort((a: string, b: string) => Number(b) - Number(a));
      }

      // Add "All" option at the beginning of the list
      sortedOptions.unshift('All');

      setFilterOptions((prev) => ({ ...prev, [field]: sortedOptions }));
    } catch (error) {
      console.error(`Error fetching filter options for ${field}:`, error);
    }
  };

  // Fetch search results
  const fetchResults = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.alientrader.cards/data/searchdb', {
        params: { q: debouncedSearchQuery, ...filters },
      });

      const fetchedResults = response.data.map((entry) => ({
        card_id: entry.card_id,
        card_owner: entry.card_owner,
        cover_image: entry.cover_image,
        card_grade_number: entry.card_grade_number,
      }));

      setResults(fetchedResults);
    } catch (error) {
      console.error('Error fetching results:', error);
    } finally {
      setLoading(false);
    }
  }, [debouncedSearchQuery, filters]);

  // Fetch initial options and results
  useEffect(() => {
    fetchFilterOptions('card_game', 'alphabetical', {});
    fetchFilterOptions('card_set', 'alphabetical', {});
    fetchFilterOptions('card_set_year', 'numerical', {});
    fetchFilterOptions('card_language', 'alphabetical', {});
    fetchFilterOptions('card_grader', 'alphabetical', {});
    fetchFilterOptions('card_grade_number', 'numerical', {});
    fetchFilterOptions('card_owner', 'alphabetical', {});
    fetchResults();
  }, [fetchResults]);

  // Handle filter selection and update dropdowns dynamically
  const handleFilterChange = (field: string, value: string) => {
    const updatedFilters = { ...filters, [field]: value === 'All' ? undefined : value }; // Reset filter if "All" is selected
    setFilters(updatedFilters);

    // Refetch options for other fields
    const otherFields = Object.keys(filterOptions).filter((f) => f !== field);
    otherFields.forEach((f) => {
      const sortType = f === 'card_set_year' ? 'numerical' : 'alphabetical';
      fetchFilterOptions(f, sortType, updatedFilters);
    });

    // Refetch results based on updated filters
    fetchResults();
  };

  return (
    <div className='searchDBdiv'>
      <Container fluid>
        <Row className="searchBarContainer">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="searchDBbar"
          />
        </Row>
        <Row className='searchFilterContainer'>
          <Col>
            <Row>
              <label htmlFor="card_game">Game:</label>
            </Row>
            <Row>
              <select
                id="card_game"
                value={filters['card_game'] || 'All'}
                onChange={(e) => handleFilterChange('card_game', e.target.value)}
              >
                {filterOptions['card_game']?.map((option, idx) => (
                  <option key={idx} value={option === 'All' ? '' : option}>
                    {option}
                  </option>
                ))}
              </select>
            </Row>
          </Col>
          <Col>
            <Row>
              <label htmlFor="card_set">Set:</label>
            </Row>
            <Row>
              <select
                id="card_set"
                value={filters['card_set'] || 'All'}
                onChange={(e) => handleFilterChange('card_set', e.target.value)}
              >
                {filterOptions['card_set']?.map((option, idx) => (
                  <option key={idx} value={option === 'All' ? '' : option}>
                    {option}
                  </option>
                ))}
              </select>
            </Row>
          </Col>
          <Col>
            <Row>
              <label htmlFor="card_set_year">Year:</label>
            </Row>
            <Row>
              <select
                id="card_set_year"
                value={filters['card_set_year'] || 'All'}
                onChange={(e) => handleFilterChange('card_set_year', e.target.value)}
              >
                {filterOptions['card_set_year']?.map((option, idx) => (
                  <option key={idx} value={option === 'All' ? '' : option}>
                    {option}
                  </option>
                ))}
              </select>
            </Row>
          </Col>
          <Col>
            <Row>
              <label htmlFor="card_language">Language:</label>
            </Row>
            <Row>
              <select
                id="card_language"
                value={filters['card_language'] || 'All'}
                onChange={(e) => handleFilterChange('card_language', e.target.value)}
              >
                {filterOptions['card_language']?.map((option, idx) => (
                  <option key={idx} value={option === 'All' ? '' : option}>
                    {option}
                  </option>
                ))}
              </select>
            </Row>
          </Col>
          <Col>
            <Row>
              <label htmlFor="card_grader">Grader:</label>
            </Row>
            <Row>
              <select
                id="card_grader"
                value={filters['card_grader'] || 'All'}
                onChange={(e) => handleFilterChange('card_grader', e.target.value)}
              >
                {filterOptions['card_grader']?.map((option, idx) => (
                  <option key={idx} value={option === 'All' ? '' : option}>
                    {option}
                  </option>
                ))}
              </select>
            </Row>
          </Col>
          <Col>
            <Row>
              <label htmlFor="card_grade_number">Grade:</label>
            </Row>
            <Row>
              <select
                id="card_grade_number"
                value={filters['card_grade_number'] || 'All'}
                onChange={(e) => handleFilterChange('card_grade_number', e.target.value)}
              >
                {filterOptions['card_grade_number']?.map((option, idx) => (
                  <option key={idx} value={option === 'All' ? '' : option}>
                    {option}
                  </option>
                ))}
              </select>
            </Row>
          </Col>
          <Col>
            <Row>
              <label htmlFor="card_owner">Owner:</label>
            </Row>
            <Row>
              <select
                id="card_owner"
                value={filters['card_owner'] || 'All'}
                onChange={(e) => handleFilterChange('card_owner', e.target.value)}
              >
                {filterOptions['card_owner']?.map((option, idx) => (
                  <option key={idx} value={option === 'All' ? '' : option}>
                    {option}
                  </option>
                ))}
              </select>
            </Row>
          </Col>
        </Row>

        <Row>
          <br />
        </Row>

        <div>
          {loading ? (
            <p>Loading...</p>
          ) : results.length > 0 ? (
            <Row>
              {results.map((result) => (
                <Col className="imagesPageRow d-flex align-items-center justify-content-center" key={result.card_id}>
                  <CoverDisplay image={result} />
                </Col>
              ))}
            </Row>
          ) : (
            <p>No results found</p>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SearchPage;
