import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/Space-4.jpg";
import CardStack from "../../assets/Stack.jpg"
import AlienHead from "../../assets/Alien-TransATC.png"
import "./HomeV2.css";
//import CardWall from '../card/images.js'
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";

function Home() {
  return (
    <div className="blackHome">
      <div className="homeV2" style={{ backgroundImage: `url(${BannerImage})` }}>
        <div className="homeAlienContainer d-flex justify-content-center">
          <div className="homeAlienHead" style={{ backgroundImage: `url(${AlienHead})` }}></div>
        </div>
      </div>
      <Container>
        <Row className='welcomeMessageV2 d-flex justify-content-center shadow-sm'>
          <div>
            Welcome to Alien Trader Co, where we're boldly going where no collector has gone before! We're a team of intergalactic traders on a mission to bring you the most out-of-this-world trading cards, sports cards, and collectibles. We promise that our prices are not from another planet, and our selection is simply out of this world. So, come on in and browse our collection - we promise it won't abduct all your cash!
          </div>
        </Row>
        <Row className='cardStack2Row d-flex justify-content-center shadow-sm'>
          <div className="cardStack2" style={{ backgroundImage: `url(${CardStack})` }}>
          <div className="homeAlienContainer d-flex justify-content-center">
          <Link to="/menu">
            <button> eBay Store </button>
          </Link>
        </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
